import React, { useCallback, useEffect, useState } from 'react'
import { AppProvider, Business, ChatProvider, InitConfig, LangProvider, VisitorProvider } from 'alpha-chat'
import Widget from './Widget'
import queryString from 'query-string'

type QueryProps = {
  apiKey?: string
  isProduction?: boolean
  enableLog?: boolean
  visibleOnLoad?: boolean
  gtmId?: string
  //
  visitor_id?: string | null
  _goid?: string | null
  token?: string | null
  business_uid?: Business | null
  contact?: any | null

  disableNotifTitle?: boolean
  disableNotifSound?: boolean
}

const App: React.FC = () => {
  const [appIsReady, setAppIsReady] = useState(false)
  const [visibleOnLoad, setVisibleOnLoad] = useState(true)
  const [screen, setScreen] = useState<
    { height: number; width: number } | undefined
  >()

  const initGTM = (gtmId: string) => {
    const script = document.createElement('script');
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${gtmId}');
    `;
    document.head.appendChild(script);
  }

  const init = useCallback(
    (query: QueryProps) => {
      if (!appIsReady) {
        const apiUrl = process.env.REACT_APP_API_URL
        const apiKey = query?.apiKey
        const isProduction =
          typeof query?.isProduction === 'undefined'
            ? typeof process.env.REACT_APP_ENV === 'undefined'
              ? false
              : process.env.REACT_APP_ENV.toLowerCase() === 'production'
            : query.isProduction

        const enableLog =
          typeof query?.enableLog === 'undefined'
            ? typeof process.env.REACT_APP_ENABLE_LOG === 'undefined'
              ? !isProduction
              : process.env.REACT_APP_ENABLE_LOG.toLowerCase() === 'true'
            : query.enableLog

        !isProduction && console.log('query init', query)
        if (apiKey && apiUrl) {
          InitConfig({ ...query, apiKey, apiUrl, isProduction, enableLog })
          setAppIsReady(true)
          window.top?.postMessage({ appIsReady: true }, '*')
        }

        if (typeof query?.visibleOnLoad !== 'undefined') {
          setVisibleOnLoad(query.visibleOnLoad)
        }

        const gtmId = query?.gtmId
        if(typeof gtmId !== 'undefined'){
          initGTM(gtmId)
        }
      }
    },
    [appIsReady]
  )

  window.onmessage = useCallback(
    (e) => {
      if (e.data.type !== 'alpha-chat') return

      switch (e.data.action) {
        case 'init':
          init(e.data.config)
          break
        case 'screen':
          setScreen(e.data.screen)
          break
        default:
          break
      }
    },
    [init, setScreen]
  )

  useEffect(() => {
    if (window.location.search) {
      const query = queryString.parse(window.location.search) as any
      init(query)
      if(query.height && query.width){
        setScreen({height: query.height, width: query.width})
      }
    }
  }, [init, window.location.search])

  if (appIsReady && screen)
    return (
      <AppProvider>
        <VisitorProvider>
          <ChatProvider>
            <LangProvider>
              <Widget visibleOnLoad={visibleOnLoad} initialScreen={screen} />
            </LangProvider>
          </ChatProvider>
        </VisitorProvider>
      </AppProvider>
    )

  return <></>
}

export default App
